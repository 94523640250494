/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/foundation-sites@6.6.3/dist/css/foundation-float.min.css
 * - /npm/motion-ui@2.0.3/dist/motion-ui.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
